import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

import './App.css';

function App() {
  return (
    <div className="App">
    <WhatsAppWidget
          phoneNo="+358458422193"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={true}
          autoOpenTimer={5000}
          messageBox={true}
          messageBoxTxt=""
          iconSize="40"
          iconColor="white"
          iconBgColor="tomato"
          headerIcon=""
          headerIconColor="pink"
          headerTxtColor="black"
          headerBgColor="tomato"
          headerTitle="Sairaanhoitopalvelu T.K. Jylhä"
          headerCaption="Online"
          bodyBgColor="#bbb"
          chatPersonName="Support"
          chatMessage={<>Hei 👋 <br /><br /> Miten voin auttaa sinua?</>}
          footerBgColor="#999"
          btnBgColor="yellow"
          btnTxtColor="black"
          btnTxt="Aloita whatsapp keskustelu"
        />
      );

    </div>
  );
}

export default App;
